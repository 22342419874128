<template>
	<v-card color="transparent" flat class="statistics">
		<div class="statistics__cards">
			<v-card
				class="statistics__cards__card statistics__cards__card--open-issues"
				flat
			>
				<v-skeleton-loader
					type="list-item-avatar-two-line"
					class="statistics__cards__skeleton"
					v-if="!opened"
				/>

				<v-avatar
					class="statistics__cards__card__avatar"
					size="70"
					color="rgba(255, 82, 82, 0.1)"
					v-if="opened"
				>
					<v-icon color="#ff5252">error_outline</v-icon>
				</v-avatar>

				<v-card-title class="statistics__cards__card__info" v-if="opened">
					<span>{{ cards.opened }}</span>
					<p>Pendências em aberto</p>
				</v-card-title>
			</v-card>

			<v-card
				class="statistics__cards__card statistics__cards__card--todo-week"
				flat
			>
				<v-skeleton-loader
					type="list-item-avatar-two-line"
					class="statistics__cards__skeleton"
					v-if="!pending"
				/>

				<v-avatar
					class="statistics__cards__card__avatar"
					size="70"
					color="rgba(98, 0, 234, 0.1)"
					v-if="pending"
				>
					<v-icon color="#6200EA">query_builder</v-icon>
				</v-avatar>

				<v-card-title class="statistics__cards__card__info" v-if="pending">
					<span>{{ cards.pending || 0 }}h</span>
					<p>Pendentes esta semana</p>
				</v-card-title>
			</v-card>

			<v-card
				class="statistics__cards__card statistics__cards__card--todo-end"
				flat
			>
				<v-avatar
					class="statistics__cards__card__avatar"
					size="70"
					color="rgba(76, 175, 80, 0.1)"
				>
					<v-icon color="#4CAF50">signal_cellular_alt</v-icon>
				</v-avatar>

				<v-card-title class="statistics__cards__card__info">
					<span>93%</span>
					<p>Pendências finalizadas</p>
				</v-card-title>
			</v-card>
		</div>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	props: {
		showVisualize: Boolean,
		pendency: Object,
	},
	computed: {
		...mapGetters({ cards: 'pendencies/cards' }),
		opened() {
			return this.cards.opened || this.cards.opened >= 0
		},
		pending() {
			return this.cards.pending || this.cards.pending >= 0
		},
	},
	methods: {
		pushToDetail() {
			const { pendency } = this
			this.$router.push({
				name: 'details-pendency',
				params: {
					pendency,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.statistics {
	&__cards {
		padding: 27px 0 32px;
		display: flex;
		justify-content: space-between;
		@media (max-width: $sm) {
			flex-direction: column;
		}
		&__skeleton {
			width: 100%;
		}
		&__card {
			display: flex;
			width: 30%;
			align-items: center;
			height: 100px;
			border-radius: 10px;
			padding: 15px 0 15px 20px;
			border-bottom-width: 5px;
			border-bottom-style: solid;
			@media (max-width: $sm) {
				width: 100%;
				margin-top: 10px;
			}
			&--open-issues {
				span {
					color: #ff5252;
				}
				border-bottom-color: rgba(255, 82, 82, 0.1);
			}
			&--todo-week {
				span {
					color: #6200ea;
				}
				border-bottom-color: rgba(98, 0, 234, 0.1);
			}
			&--todo-end {
				span {
					color: #4caf50;
				}
				border-bottom-color: rgba(76, 175, 80, 0.1);
			}
			&__avatar {
				border-radius: 50% !important;
			}
			&__info {
				display: flex;
				flex-direction: column;
				align-items: flex-start !important;
				justify-content: center;
				margin-left: 22px;
				span {
					font-size: 28px;
					font-weight: 600;
				}
				p {
					color: #a6acbe;
					font-size: 14px;
					font-weight: 300;
					margin-bottom: 0 !important;
				}
			}
		}
	}
}
</style>
