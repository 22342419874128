<template>
	<div class="header-dash">
		<div class="header-dash__title">
			<h1>Dashboard Pendências</h1>
			<p>Gestão completa de pendências</p>
		</div>

		<div class="header-dash__actions">
			<v-btn
				class="header-dash__actions__btn"
				outlined
				height="44"
				width="228"
				color="#1e90ff"
				v-show="pendency.id"
				@click="pushToDetail"
			>
				<v-icon color="#1E90FF">visibility</v-icon>
				Visualizar pendência
			</v-btn>

			<v-btn
				class="header-dash__actions__btn  text-center"
				color="primary"
				dark
				:to="{ name: 'create-pendency' }"
				depressed
				height="44"
				width="228"
			>
				<v-icon color="#ffffff">add</v-icon>
				CRIAR NOVA PENDÊNCIA
			</v-btn>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters({ pendency: 'pendencies/pendency' }),
	},
	methods: {
		pushToDetail() {
			this.$router.push({
				name: 'details-pendency',
				params: {
					id: this.pendency.id,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.header-dash {
	background: transparent !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: $sm) {
		flex-direction: column;
	}

	&__title {
		h1 {
			font-size: 32px;
			font-weight: 500;
			color: rgba(0, 0, 0, 0.87);
		}
		p {
			font-size: 12px;
			font-weight: 400;
			color: #a6acbe;
			@media (max-width: $sm) {
				text-align: center;
			}
		}
	}
	&__actions {
		&__btn {
			&:first-child {
				margin-right: 15px;
				border-color: #1e90ff;
			}
		}
	}
}
</style>
