<template>
	<v-card flat class="calendar">
		<div class="calendar__onDay"></div>
		<div class="calendar__item">
			<v-toolbar flat color="#fff" class="toolbar">
				<div class="calendar__item__year">
					<v-btn icon color="#26283B" @click="prevMonth">
						<v-icon>navigate_before</v-icon>
					</v-btn>
					<span>{{ getCurrentMonth }}</span>
					<v-btn icon @click="nextMonth">
						<v-icon color="#26283B">navigate_next</v-icon>
					</v-btn>
				</div>
			</v-toolbar>
			<ul class="calendar__item__daysOfWeek">
				<li
					v-for="(day, index) in daysOfWeek"
					:key="`daysOfWeek_${day}_${index}`"
				>
					{{ day }}
				</li>
			</ul>

			<ul class="calendar__item__days">
				<li
					v-for="(day, index) in getDaysOfMonth"
					:key="`daysInMonth_${day}_${index}`"
				>
					<v-tooltip right :disabled="!checkIfIsDay(day)">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								fab
								x-small
								:color="
									selectedDay === day
										? checkIfIsDay(day) && selectedDay === day
											? '#fc0000'
											: '#1E90FF'
										: ''
								"
								:text="selectedDay !== day"
								depressed
								:disabled="checkIsNotDay(day) || day === ''"
								v-bind="attrs"
								v-on="on"
								@click="changeDay(day)"
							>
								<span
									:class="{
										'text-active': selectedDay === day,
										'text-error': checkIfIsDay(day),
										'text-error--active':
											checkIfIsDay(day) && selectedDay === day,
										'text-disabled': checkIsNotDay(day),
									}"
									>{{ day }}</span
								>
							</v-btn>
						</template>
						<span>{{ showHours(day) }} horas pendentes</span>
					</v-tooltip>
				</li>
			</ul>
		</div>
	</v-card>
</template>

<script>
import {
	parseISO,
	format,
	getISODay,
	getDaysInMonth,
	getMonth,
	getYear,
	startOfMonth,
	getDate,
	isMonday,
	isTuesday,
	isWednesday,
	isThursday,
	isFriday,
	isSaturday,
	isSunday,
} from 'date-fns'
import { pt } from 'date-fns/locale'
import { mapGetters } from 'vuex'

export default {
	data: () => ({
		selectedDate: new Date(),
		daysOfWeek: ['S', 'T', 'Q', 'Q', 'S', 'S', 'D'],
		currentMonth: getMonth(new Date()) + 1,
		currentYear: getYear(new Date()),
		selectedDay: getDate(new Date()),
		today: getDate(new Date()),
	}),
	computed: {
		...mapGetters({ getDays: 'pendencies/getDays', me: 'user/getMe' }),
		getCurrentMonth() {
			return format(this.selectedDate, 'MMM yyyy', { locale: pt })
		},
		getDaysOfMonth() {
			const daysCount = getDaysInMonth(this.selectedDate)
			const days = this.checkIsDay(startOfMonth(this.selectedDate))

			for (let i = 0; i < daysCount; i++) {
				days.push(i + 1)
			}

			return days
		},
	},
	watch: {
		getDays() {
			this.checkIsDay()
		},
		me() {
			if (this.me.employee_id) {
				this.requestDays()
			}
		},
	},
	mounted() {
		this.$emit('input', this.selectedDate)
	},
	methods: {
		prevMonth() {
			const month = getMonth(this.selectedDate)

			if (this.currentMonth === 1) {
				this.currentYear =
					getYear(
						new Date(
							`${this.currentYear}-${this.currentMonth}-${this.selectedDay} 00:00:00`
						)
					) - 1
				this.currentMonth = 12
				this.selectedDate = new Date(
					`${this.currentYear}-${this.currentMonth}-${this.selectedDay} 00:00:00`
				)
				return
			}
			this.currentMonth = month
			this.selectedDate = new Date(
				`${this.currentYear}-${month}-${this.selectedDay} 00:00:00`
			)

			this.$store.dispatch('pendencies/getDays', {
				id: this.me.employee_id,
				month,
				year: this.currentYear,
			})
			this.checkIfIsDay()
		},
		nextMonth() {
			const month = getMonth(this.selectedDate) + 2
			if (this.currentMonth === 12) {
				this.currentYear =
					getYear(
						new Date(
							`${this.currentYear}-${this.currentMonth}-${this.selectedDay} 00:00:00`
						)
					) + 1
				this.currentMonth = 1
				this.selectedDate = new Date(
					`${this.currentYear}-${this.currentMonth}-${this.selectedDay} 00:00:00`
				)
				return
			}

			this.currentMonth = month
			this.selectedDate = new Date(
				`${this.currentYear}-${month}-${this.selectedDay} 00:00:00`
			)
			this.requestDays()
		},
		checkIsNotDay(day) {
			if (day > 0) {
				const dateNow = format(new Date(), 'yyyy-MM-dd')
				const date = format(
					new Date(`${this.currentYear}-${this.currentMonth}-${day} 00:00:00`),
					'yyyy-MM-dd'
				)

				if (date > dateNow) {
					return true
				}

				return false
			}

			return false
		},
		changeDay(day) {
			this.selectedDay = day
			this.selectedDate = new Date(
				`${this.currentYear}-${this.currentMonth}-${day} 00:00:00`
			)

			this.$emit('input', this.selectedDate)
		},
		checkIsDay(date) {
			if (isMonday(date)) return []
			if (isTuesday(date)) return ['']
			if (isWednesday(date)) return ['', '']
			if (isThursday(date)) return ['', '', '']
			if (isFriday(date)) return ['', '', '', '']
			if (isSaturday(date)) return ['', '', '', '', '']
			if (isSunday(date)) return ['', '', '', '', '', '']
		},
		async requestDays() {
			try {
				await this.$store.dispatch('pendencies/getDays', {
					id: this.me.employee_id,
					month: this.currentMonth,
					year: this.currentYear,
				})
			} catch (e) {
				console.log(e)
			}
		},
		checkIfIsDay(day) {
			if (day > 0) {
				const date = format(
					new Date(`${this.currentYear}-${this.currentMonth}-${day} 00:00:00`),
					'yyyy-MM-dd'
				)

				const check = this.getDays.filter(item => item.day === date)

				return check.length > 0
			}

			return false
		},
		showHours(day) {
			if (day > 0) {
				const date = format(
					new Date(`${this.currentYear}-${this.currentMonth}-${day} 00:00:00`),
					'yyyy-MM-dd'
				)

				const check = this.getDays.filter(item => item.day === date)

				if (check.length > 0) {
					return check[0].hours
				}
				return false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.calendar {
	&__item {
		&__year {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			span {
				font-weight: 500;
				font-size: 14px;
				text-transform: capitalize;
			}
		}
		&__daysOfWeek {
			display: flex;
			list-style: none;
			justify-content: space-between;
			padding-left: 0 !important;
			li {
				color: rgba(0, 0, 0, 0.543337);
				width: calc(100% / 7);
				text-align: center;
			}
		}

		&__days {
			display: flex;
			list-style: none;
			flex-wrap: wrap;
			padding-left: 0 !important;
			li {
				width: calc(100% / 7);
				text-align: center;
				margin-top: 5px;
				span {
					font-size: 12px;
					color: #26283b;
				}

				.text-active {
					color: #fff;
				}

				.text-disabled {
					color: #c4c7d7;
				}

				.text-error {
					color: #fc0000;
					font-weight: bold;
					&--active {
						color: #fff !important;
					}
				}
			}
		}
	}
}
</style>
