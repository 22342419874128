<template>
	<div class="dashboard">
		<Header />
		<Statistics />
		<div class="flex flex--justify-between">
			<v-card class="card-time-record" flat>
				<TimeRecord />
			</v-card>
			<Pendencies />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '@/components/unique/Dashboard/Header'
import TimeRecord from '@/components/organisms/TimeRecord'
import Statistics from '@/components/unique/Dashboard/Statistics'
import Pendencies from '@/components/unique/Dashboard/Pendencies'

export default {
	data: () => ({
		showVisualize: false,
		clearSelectedPendency: false,
	}),
	components: { Header, TimeRecord, Statistics, Pendencies },
	computed: {
		...mapGetters({ me: 'user/getMe', pendency: 'pendencies/pendency' }),
	},

	watch: {
		pendency: {
			deep: true,
			handler() {
				if (this.pendency.id) {
					this.$store.dispatch('pendencies/getTypes', this.pendency.id)
					console.log(this.pendency, 'oii existo')
				}
			},
		},
	},

	methods: {
		// getPendencyFromEmit(pendency) {
		// 	if (pendency !== undefined) {
		// 		this.currentPendency = pendency
		// 		this.showVisualize = true
		// 		this.$store.dispatch('pendencies/getTypes', this.currentPendency.id)
		// 		console.log(this.currentPendency.id)
		// 	} else {
		// 		this.currentPendency = {}
		// 		this.showVisualize = false
		// 	}
		// },
		// async changeClearSelectedPendency() {
		// 	this.clearSelectedPendency = await true
		// 	this.showVisualize = await false
		// 	this.resetClearSelectedPendency()
		// },
		// resetClearSelectedPendency() {
		// 	this.clearSelectedPendency = false
		// },
	},
}
</script>

<style lang="scss">
.flex {
	display: flex;
	@media (max-width: 600px) {
		flex-direction: column;
	}
	&--justify-between {
		justify-content: space-between;
	}
}

.dashboard {
	&__success-message {
		align-items: center;
		background: #4caf50;
		border-radius: 10px;
		color: #fff;
		display: flex;
		padding: 15px 15px 15px 35px;
		text-transform: uppercase;
		width: 100%;
	}
}

.card-time-record {
	border-radius: 50px;
	width: 65%;
	margin-bottom: 60px;
	padding-bottom: 50px;
	@media (max-width: 600px) {
		width: 100%;
	}
}
</style>
