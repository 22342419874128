<template>
	<v-card flat class="v-card">
		<v-card-title class="v-card-title">
			<span class="title"> Pendências recentes</span>
			<span class="hours">Horas</span>
		</v-card-title>
		<v-divider />

		<v-sheet v-if="!me.employee_id">
			<v-skeleton-loader
				type="list-item-avatar, list-item-content"
				class="mt-5"
				v-for="(item, index) in 6"
				:key="index"
			/>
		</v-sheet>

		<v-list v-else>
			<v-list-item
				v-for="(item, index) in report"
				:class="pendency.id === item.id ? 'd-flex bg-blue' : 'd-flex'"
				:key="item.id"
				@click="selectPendency(item)"
			>
				<v-list-item-avatar>
					<v-avatar
						:color="pendency.id === item.id ? '#1E90ff' : '#C4C7D7'"
						size="40"
					>
						<span
							:class="pendency.id === item.id ? 'initials-white' : 'initials'"
							>{{ index + 1 }}</span
						>
					</v-avatar>
				</v-list-item-avatar>
				<v-list-item-content
					:class="pendency.id === item.id ? 'description__blue' : 'description'"
				>
					{{ item.name || item.description }}
				</v-list-item-content>
				<v-list-item-action
					:class="pendency.id === item.id ? 'numbers__blue' : 'numbers'"
				>
					{{ showHours(item.hours) }}
				</v-list-item-action>
			</v-list-item>
		</v-list>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data: () => ({
		showClick: false,
		selectedItem: 1,
	}),
	computed: {
		...mapGetters({
			report: 'pendencies/report',
			me: 'user/getMe',
			pendency: 'pendencies/pendency',
			foundReports: 'pendencies/foundReports',
			pendencyBool: 'pendencies/pendencyBool',
		}),
	},
	watch: {
		async me() {
			if (this.me.employee_id) {
				await this.$store.dispatch('pendencies/getReport', this.me.employee_id)
			}
		},
	},

	methods: {
		showHours(hours) {
			return hours
				? hours.toLocaleString('pt-BR', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
				  })
				: 0
		},
		selectPendency(pendency) {
			if (
				Object.keys(this.pendency).length == 0 ||
				pendency.id !== this.pendency.id
			) {
				this.$store.dispatch('pendencies/getPendency', pendency)
				this.$store.dispatch('pendencies/clearPendencyBool', false)
			} else {
				this.$store.dispatch('pendencies/getPendency', {})
				this.$store.dispatch('pendencies/clearPendencyBool', true)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.v-card {
	margin-bottom: 60px;
	border-radius: 10px;
	width: 30%;
	@media (max-width: 600px) {
		width: 100%;
	}
}
.bg-blue {
	background: rgb(30, 144, 255, 0.1);
	cursor: pointer;
}

.v-card-title {
	justify-content: space-between;

	.title {
		color: rgba(0, 0, 0, 0.87);
		font-size: 14px !important;
	}
	.hours {
		color: gray;
		font-size: 14px;
		line-height: 22px;
	}
}

.initials {
	color: #717786;
}

.initials-white {
	color: #ffffff;
}

.description {
	color: #717786;
	cursor: pointer;
	font-style: italic;
	font-size: 14px;
	line-height: 22px;

	&__blue {
		color: #1e90ff;
		font-style: italic;
		font-size: 14px;
		line-height: 22px;
	}
}
.numbers {
	color: #000000;
	font-size: 14px;
	line-height: 22px;
	font-weight: 500;

	&__blue {
		color: #1e90ff;
		font-size: 14px;
		line-height: 22px;
	}
}
</style>
