<template>
	<v-card class="v-card" style="border-radius:50px !important;" flat>
		<v-card-title class="card-title">
			Cadastro rápido de horas
		</v-card-title>
		<v-divider />

		<v-card-text class="flex">
			<v-card flat class="pa-5 form-container">
				<v-form
					class="form d-flex flex-column justify-space-around"
					@submit.prevent="submit"
				>
					<v-autocomplete
						v-model="select"
						label="Digite o nome ou ID da pendência"
						:items="itemsSearch"
						:loading="loading"
						filled
						height="55px"
						prepend-inner-icon="search"
						:search-input.sync="search"
						return-object
						item-text="Descricao"
						item-value="Pendencia_Id"
						hide-no-data
						clearable
						@click:clear="clearSelected"
						@change="chooseItem"
						no-filter
					>
						<template v-slot:item="{ item }">
							<v-list-item-content width="200">
								<v-list-item-title>{{ item.Descricao }}</v-list-item-title>
								<v-list-item-subtitle>{{
									item.Pendencia_Id
								}}</v-list-item-subtitle>
							</v-list-item-content>
						</template>
					</v-autocomplete>

					<div class="d-flex">
						<v-text-field
							label="Tempo"
							filled
							height="55px"
							type="number"
							min="0"
							v-model="form.hours"
						/>
						<v-autocomplete
							:items="items"
							label="Tipo"
							height="40px"
							filled
							class="ml-3"
							item-text="name"
							item-value="id"
							v-model="form.type_id"
						/>
					</div>

					<v-textarea
						filled
						label="Digite a descrição do trabalho realizado..."
						height="120px"
						no-resize
						v-model="form.description"
					/>
					<div>
						<v-btn
							color="primary"
							class="button"
							elevation="0"
							type="submit"
							:disabled="validateForm ? true : false"
							>Cadastrar</v-btn
						>
						<v-btn
							v-if="showClearButton"
							outlined
							color="primary"
							class="button ml-3"
							elevation="0"
							@click="clearSelected"
							>Limpar</v-btn
						>
					</div>
				</v-form>
			</v-card>

			<v-card
				flat
				class="pa-5 calendar-container"
				style="padding-top: 0 !important;"
			>
				<Calendar v-model="date" @input="getSelectedDate($event)" />
			</v-card>
		</v-card-text>
	</v-card>
</template>

<script>
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import Calendar from '@/components/atoms/Calendar'

export default {
	data: () => ({
		search: null,
		select: {},
		loading: false,
		items: [],
		date: '',
		itemsSearch: [],
		form: {
			pendency_id: '',
			sponsor_id: '',
			hours: '',
			description: '',
			type_id: '',
			date: '',
		},
	}),
	// props: {
	// 	pendency: Object,
	// },
	components: { Calendar },
	computed: {
		...mapGetters({
			foundReports: 'pendencies/foundReports',
			report: 'pendencies/report',
			me: 'user/getMe',
			pendency: 'pendencies/pendency',
			clearPendency: 'pendencies/pendencyBool',
		}),

		validateForm() {
			const { pendency_id, hours, type_id, description } = this.form

			return (
				description === '' ||
				hours === '' ||
				type_id === '' ||
				pendency_id === undefined
			)
		},

		formatPendency() {
			const newFormat = {
				Pendencia_Id: this.pendency.id,
				Descricao: this.pendency.description,
			}

			if (this.pendency.id) return newFormat
		},

		showClearButton() {
			const { pendency_id, hours, type_id, description } = this.form

			return (
				description !== '' ||
				hours !== '' ||
				type_id !== '' ||
				pendency_id !== undefined
			)
		},
	},

	watch: {
		pendency() {
			if (Object.keys(this.pendency).length > 0) {
				this.select = this.formatPendency
				this.itemsSearch = [this.formatPendency]
				this.chooseItem()
			}
		},
		search(val) {
			if (!val || val.length === 0) {
				this.items = []
				return
			}

			this.loading = true
			this.debouce(val)
		},

		foundReports() {
			if (this.foundReports.length === 0) {
				this.items = []
			}
		},

		select: {
			handler() {
				if (Object.keys(this.select).length > 0) {
					this.form.pendency_id = this.select.Pendencia_Id
				}
			},
			deep: true,
		},
		clearPendency() {
			if (this.clearPendency) {
				this.clearSelected()
			}
		},
	},
	methods: {
		debouce(val) {
			clearTimeout(this.searchTimer)
			this.searchTimer = setTimeout(() => {
				this.searchPendency(val)
			}, 300)
		},

		getSelectedDate(value) {
			this.form.date = value
		},

		async chooseItem() {
			if (this.select.Pendencia_Id)
				this.items = await this.$store.dispatch(
					'pendencies/getTypes',
					this.select.Pendencia_Id
				)
		},

		async submit() {
			this.form.sponsor_id = this.me.employee_id
			this.form.date = format(this.date, 'yyyy-MM-dd')
			try {
				await this.$store.dispatch('pendencies/registerTime', this.form)
				this.$store.dispatch('setSnackbar', {
					status: true,
					message: 'Hora registrada com sucesso.',
				})
				await this.$store.dispatch('pendencies/getReport', this.me.employee_id)
				await this.$store.dispatch(
					'pendencies/getInfoCards',
					this.me.employee_id
				)
				await this.$store.dispatch('pendencies/getDays', {
					id: this.me.employee_id,
				})
				this.clearSelected()
			} catch (error) {
				this.$store.dispatch('setSnackbar', {
					status: true,
					message: error.data.response.message,
				})
				throw e
			}
		},

		async searchPendency(val) {
			if (val.length > 2)
				await this.$store.dispatch('pendencies/queryReports', val)
			this.itemsSearch = this.foundReports
			this.loading = false
		},

		clearFields() {
			this.select = null
			this.search = null
			this.$store.state.pendencies.foundReports = []
			this.itemsSearch = null
			this.$store.dispatch('pendencies/getPendency', {})
			const arr = Object.keys(this.form)
			arr.map(item => {
				this.form[item] = ''
			})
			setTimeout(() => {
				this.select = {}
			}, 200)
			setTimeout(() => {
				this.itemsSearch = []
			}, 200)
		},
		clearSelected() {
			this.clearFields()
			this.$store.dispatch('pendencies/getPendency', {})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.flex {
	display: flex;
	@media (max-width: $sm) {
		flex-direction: column-reverse;
	}
}

.v-card {
	border-radius: 50px !important;
}

.card-title {
	color: rgba(0, 0, 0, 0.6);
	font-weight: 500;
	font-size: 20px;
	line-height: 32px;
}

.form-container {
	width: 60%;
	height: 330px;

	@media (max-width: $sm) {
		width: 100%;
	}
}

.calendar-container {
	width: 40%;
	@media (max-width: $sm) {
		width: 100%;
	}
}

.button {
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	width: 113px;
}
</style>
